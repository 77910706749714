
import { useQueries } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import Clock from 'react-clock';
import axios from '../../../api/axios';
import Navbar from '../../../components/Navbar';
import 'react-clock/dist/Clock.css';
import Loaders from '../../../components/Loaders';

const Home = () => {
    const [avgTime, setAvgTime] = useState();
    const [value, setValue] = useState(new Date());
    const handleOrderStatistic = () => {
        const res = axios.get(`order/statistic`)
        return res;
    };

    const handleOngoingOrder = () => {
        const res = axios.get(`order/dashboard/ongoing-orders`)
        return res;
    };

    const handleShift = () => {
        const res = axios.get(`dashboard/shift/all`)
        return res;
    };
    const [statisticQuery, ongoingQuery, shiftQuery] = useQueries({
        queries: [
            {
                queryKey: 'get-Statistic',
                queryFn: handleOrderStatistic,
                cacheTime: 5000,
            },
            {
                queryKey: 'get-ongoing',
                queryFn: handleOngoingOrder,
                cacheTime: 5000,
            },
            {
                queryKey: 'get-shift',
                queryFn: handleShift,
                cacheTime: 5000,
            }
        ]

    })


    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);




    useEffect(() => {
        const timeStr = statisticQuery?.data?.data?.data?.avg_packed_time === "0" ? "00:00" : statisticQuery?.data?.data?.data?.avg_packed_time || "00:00";
        const minutes = timeStr?.slice(0, 2);
        const seconds = timeStr?.slice(3, 5);
        const formattedTime = `${minutes}:${seconds}`;
        setAvgTime(formattedTime);

    }, [statisticQuery?.data])

    return (
        <div>
            <Navbar
                title="Dashboard"
            />
            {
                statisticQuery?.isLoading ? <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
                    :
                    <div className='p-5'>
                        <div className='d-flex justify-content-evenly'>
                            <div className="card card-packing">
                                <h5 className="card-header card-header-color ">Open orders</h5>
                                <div className="card-body">
                                    <h1 className='card-count'>{statisticQuery?.data?.data?.data?.open_orders}</h1>
                                </div>
                            </div>
                            <div className="card card-packing">
                                <h5 className="card-header card-header-color">Packaged today</h5>
                                <div className="card-body">
                                    <h1 className='card-count'>{statisticQuery?.data?.data?.data?.packed_today}</h1>
                                </div>
                            </div>
                          
                        </div>
                    </div>
            }


        </div >
    );
};

export default Home;