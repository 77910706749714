import React, { useMemo } from 'react'
import Navbar from '../../../components/Navbar'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import '../Employee/employee.css'
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import MaterialReactTable from "material-react-table";
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Loaders from '../../../components/Loaders';
import useAuth from '../../../hooks/useAuth';
import "./MyAttendance.css"

export default function MyAttendance() {
    const axiosPrivate = useAxiosPrivate();
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [salary, setSalary] = useState({});
    const [base64, setBase64] = useState('');
    const { userInfo } = useAuth();

    const postData = async (data) => {
        const response = axiosPrivate.post(`employee/attendance/create`, data);
        return response;
    }

    const handleCheckOut = (time, id, checkin) => {
        const checkOutTime = {
            "check_in_time": checkin,
            "check_out_time": time,
            "attendance_id": id
        }
        const response = axiosPrivate.put(`/attendance/edit/check-out`, checkOutTime);
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                refetch()

                //clear register
                setValue("check_in_time", "")
                setValue("check_out_time", "")
                setValue("attendance_date", "")

            }
            if (successData?.data?.success == false) {
                alert("Attendance Date Already taken")
            }
        },
        onError: (error) => {
            if (error) {
                alert("Something went wrong")
            }
        }

    })

    const { handleSubmit, setValue, register } = useForm();

    const handleEmployeeDetails = () => {
        const res = axiosPrivate.get(`/employee/details?employee_id=${userInfo.id}`)
        return res;
    };

    const handleWorkingTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const min = minutes % 60;
        return `${hours} h ${min} m`
    }
    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => row.attendance_date,
                id: "attendance_date", //id required if you use accessorFn instead of accessorKey
                header: "Attendance Date",
                Header: <i style={{ color: "oranage" }}>Date</i> //optional custom markup

            },
            {
                accessorFn: (row) => <div>
                    <div >
                        <p>
                            <i className="fa-solid fa-clock"></i>
                            {row?.check_in_time}
                        </p>
                    </div>

                    <div className="modal fade" id={`check_in_time-${row.id}`} tabIndex="-1" aria-labelledby={`check_in_timeLabel-${row.id}`} aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id={`check_in_timeLabel-${row.id}`}>Check In Time</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body attendance-modal-body">
                                    <TimePicker
                                        onChange={(updatedTime) => {
                                            handleCheckOut(row.check_out_time, row.id, updatedTime)
                                        }}
                                        value={row.check_in_time}
                                    />

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>,
                id: "check_in_time", //id required if you use accessorFn instead of accessorKey
                header: "Check in",
                muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</> //optional custom cell render
            },
            {
                accessorFn: (row) => <div>
                    <div >

                        {
                            row?.check_out_time ? <p><i className="fa-solid fa-clock"></i> {row?.check_out_time} </p> : <i className="fa-solid fa-times"></i>
                        }


                    </div>

                    <div className="modal fade" id={`check_out_time-${row.id}`} tabIndex="-1" aria-labelledby={`check_out_timeLabel-${row.id}`} aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id={`check_out_timeLabel-${row.id}`}>Check Out Time</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body attendance-modal-body">
                                    <TimePicker
                                        onChange={(updatedTime) => {
                                            handleCheckOut(updatedTime, row.id, row.check_in_time)
                                        }}
                                        value={row.check_out_time}
                                    />

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>, //alternate way
                id: "check_out_time", //id required if you use accessorFn instead of accessorKey
                header: "Check Out",
                Header: <i style={{ color: "red" }}>Check Out</i> //optional custom markup
            },

            {
                accessorFn: (row) => handleWorkingTime(row?.work_minutes_total),
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Duration",
                Header: <i style={{ color: "oranage" }}>Duration</i> //optional custom markup


            },
            {
                accessorFn: (row) => row?.is_payment_clear ? "Paid" : "DUE",
                id: "is_payment_clear", //id required if you use accessorFn instead of accessorKey
                header: "Payment",
                Header: <i style={{ color: "oranage" }}>Payment</i> //optional custom markup

            }
            // ,
            // {
            //     accessorFn: (row) => <button className='btn btn-warning'> <i className="fa-solid fa-paper-plane"></i></button>,
            //     id: "id", //id required if you use accessorFn instead of accessorKey
            //     header: "Action",
            //     Header: <i style={{ color: "oranage" }}>Action</i> //optional custom markup

            // }
        ],
        []
    );

    const { isLoading, isError, error, refetch } = useQuery({
        queryKey: 'employeeDetails',
        queryFn: handleEmployeeDetails,
        onSuccess: (data) => {
            setEmployeeDetails(data?.data?.data)
            setSalary(data?.data?.data?.salary)
        }
    })
    const handleImageInputChange = (event) => {
        const imageFile = event?.target?.files[0];

        if (imageFile.size > 1000000) {
            alert("File size should be less than 1MB");
            return;
        }
        const reader = new FileReader();
        const saveEmployeePhoto = async (file) => {

            const formData = {
                "avarter": file,
                "employee_id": userInfo.id
            };
            const response = await axiosPrivate.put(`/employee/update/profile-pic?employee_id=${userInfo.id}`, formData);
            return response;
        }

        reader.readAsDataURL(imageFile);
        reader.onload = () => {
            setBase64(reader?.result);
            saveEmployeePhoto(reader?.result)
        };

        //call api and save photo



    };

    useEffect(() => {
        if (salary) {
            Object.keys(salary).forEach((key) => {
                setValue(key, salary[key]);
            });
        }

    }, [salary, setValue]);

    const onSubmit = (data) => {
        const makeData = {
            "check_in_time": data.check_in_time,
            "check_out_time": data.check_out_time,
            "attendance_date": data.attendance_date,
        }

        mutate(makeData)


    }

    return (
        <>
            <Navbar
                title={`Hi  ${employeeDetails?.employee?.name}  👋`}
            />
            {
                isLoading ? <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div> : isError ? <div>{error}</div> : <div></div>
            }

            <div className='my-3 mx-5'>
                <div className="row gap-3">
                    <div className="col-3">
                        <div className="card">
                            <img src={base64 ? base64 : employeeDetails?.employee?.image} className="images-employee" alt="..." />
                            <input type="file" accept="image/*" className='btn btn-success' onChange={handleImageInputChange} />
                            <div className="card-body">
                                <h5 className='text-purple text-capitalize'>{userInfo?.role?.name === "admin" ? <i className="fa-solid fa-shield-halved"></i> : <i className="fa-solid fa-boxes-packing"></i>} {userInfo?.role?.name}</h5>
                                <h4 className="card-title">{employeeDetails?.employee?.name}</h4>
                                <h6>{employeeDetails?.employee?.email}</h6>
                                <h6>Phone:+45{employeeDetails?.employee?.phone}</h6>
                                <h6>Gendar:{employeeDetails?.employee?.gender}</h6>
                            </div>

                        </div>

                        <div className='card my-3'>
                            <div className='card-body'>
                                <h3 className='text-start'>Work Informations</h3>
                                <p className='text-start'>Total Packed : {employeeDetails?.total_packed_orders}</p>
                                <p className='text-start'>Total Working Time : {handleWorkingTime(employeeDetails?.work_minutes_total)}</p>
                                <p className='text-start'>Avg Salary/Order : {employeeDetails?.avg_salary_per_order?.toFixed(2)} dkk</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="text-start">Salary Information</h3>
                                <div className='d-flex justify-content-between'>

                                    <div>
                                        <p className='text-start text-capitalize'>Salary Type : {employeeDetails?.salary?.salary_type ? employeeDetails?.salary?.salary_type : "N/A"}</p>
                                        <p className='text-start'>Current Salary :{employeeDetails?.salary?.current_salary ? employeeDetails?.salary?.current_salary + "DKK" : "N/A"} </p>
                                    </div>
                                    <div>
                                        <p className='text-start'>Incentive Salary : {employeeDetails?.salary?.incentive_rate ? employeeDetails?.salary?.incentive_rate : "N/A"}</p>
                                        <p className='text-start'>Last Salary : {employeeDetails?.salary?.last_salary ? employeeDetails?.salary?.last_salary : "N/A"}</p>

                                    </div>

                                    {/* <button className='btn btn-salary' data-bs-toggle="modal" data-bs-target="#salaryModal">Update Salary</button>
                                    <div className="modal fade" id="salaryModal" tabIndex="-1" aria-labelledby="salaryModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h1 className="modal-title fs-5" id="salaryModalLabel">Update Salary Information</h1>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className='salary-modal-center'>
                                                            <div className='row gap-1'>
                                                                <div className='col-5'>
                                                                    <label htmlFor="inputCurrentSalary5" className="text-start">Current Salary</label>
                                                                    <input type="number" id="inputCurrentSalary5" {...register("current_salary")} className="form-control" />
                                                                </div>
                                                                <div className='col-5 '>
                                                                    <label htmlFor="IncentiveSalary5" className="text-start">Incentive Salary</label>
                                                                    <input type="number" id="IncentiveSalary5" {...register("incentive_rate")} className="form-control" />
                                                                </div>
                                                                <div className='col-5 '>
                                                                    <label htmlFor="SalaryType5" className="text-start">Salary Type</label>
                                                                    <select className="form-select"  {...register("salary_type")} aria-label="Default select example" >

                                                                        <option selected value="monthly">Monthly</option>
                                                                        <option value="weekly">Weekly</option>
                                                                        <option value="hourly">Hourly</option>
                                                                    </select>
                                                                </div>
                                                                <div className='col-5'>
                                                                    <label htmlFor="currency5" className="text-start">Currency</label>
                                                                    <select className="form-select" aria-label="Default select example"  {...register("currency_code")}>

                                                                        <option selected value="DKK">DKK</option>
                                                                        <option value="USD">USD</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Save changes</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div> */}
                                </div>



                            </div>
                        </div>
                        <div className='card my-3'>
                            <div className='card-body'>
                                <h3 className='text-start'>Attendance Information</h3>
                                <div>
                                    <button type="button" class="btn btn-primary my-3 float-end" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Register work shift
                                    </button>

                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h3 class="modal-title" id="exampleModalLabel">Register work shift</h3>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div className='d-flex justify-content-between  align-items-center my-3'>
                                                            <h4 htmlFor="inputCurrentSalary5" className="text-start">Date picker</h4>
                                                            <input type="date" {...register('attendance_date')} />
                                                        </div>
                                                        <div className='d-flex justify-content-between  align-items-center my-3'>
                                                            <h4 htmlFor="inputCurrentSalary5" className="text-start">Start Time</h4>
                                                            <input type="time"{...register('check_in_time')} />
                                                        </div>

                                                        <div className='d-flex justify-content-between  align-items-center my-3'>
                                                            <h4 htmlFor="inputCurrentSalary5" className="text-start">End Time</h4>
                                                            <input type="time"  {...register('check_out_time')} />
                                                        </div>


                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>

                                {employeeDetails?.attendances && <MaterialReactTable
                                    columns={columns}
                                    data={employeeDetails?.attendances} />}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
