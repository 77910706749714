import React, { useEffect, useState } from 'react'
import Navbar from '../../../components/Navbar'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

export default function EmployeeCreate() {
    const axiosPrivate = useAxiosPrivate();
    const [allRole, setAllRole] = useState([])
    const [role, setRole] = useState()
    const [gender, setGender] = useState('')
    const [salarytype, setSalarytype] = useState('')
    const [currency, setCurrency] = useState('')

    useEffect(() => {
        getAllRole();
    }, [])

    const getAllRole = async () => {
        const res = await axiosPrivate.get(`role/all`);
        setAllRole(res?.data?.data);
        return res;
    }

    const { register, handleSubmit, formState: { errors } } = useForm();

    const postData = async (data) => {
        const makeData = {
            "email": data.email,
            "gender": gender,
            "name": data.name,
            "password": data.password,
            "phone": data.phone,
            "role_id": role,
            "salary": {
                "salary_type": salarytype,
                "currency_code": currency,
                "current_salary": data.current_salary,
                "incentive_rate": data.incentive_rate,
            }
        }

        const response = axiosPrivate.post('packer/register', makeData);
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/employees'
                }, 3000)
            }
        }
    })

    const onSubmit = (data) => {
        mutate(data);
    }
    return (
        <>  <Navbar
            title="Create a new Employee"
        />

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    placeholder="Enter Name"
                                    fullWidth={true}
                                    required
                                    margin="normal"
                                    {...register("name")}
                                    error={!!errors?.name}
                                    helperText={errors.name?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="phone"
                                    label="Phone"
                                    variant="outlined"
                                    placeholder="Enter Phone Number"
                                    type='number'
                                    required
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("phone")}
                                    error={Boolean(errors?.phone)}
                                    helperText={errors.phone ? errors.phone?.message : ""}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    type='email'
                                    required
                                    placeholder="Enter Email"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("email")}
                                    error={Boolean(errors?.email)}
                                    helperText={errors.short_code ? errors.email?.message : ""}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="password"
                                    label="Password"
                                    required
                                    variant="outlined"
                                    placeholder="Enter Password"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("password")}
                                    error={Boolean(errors?.password)}
                                    helperText={errors.password ? errors.password?.message : ""}
                                />
                            </Grid>


                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => setRole(e.target.value)}
                                        margin="normal"
                                        required
                                        label="Role"
                                    >
                                        <MenuItem hidden value="Select User Role">Select User Role</MenuItem>
                                        {
                                            allRole?.map((role) => <MenuItem value={role?.id}>{role?.name}</MenuItem>)
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => setGender(e.target.value)}
                                        margin="normal"
                                        label="Gender"
                                    >
                                        <MenuItem hidden value="Select User Role">Select User Role</MenuItem>
                                        <MenuItem value={"male"}>Male</MenuItem>
                                        <MenuItem value={"female"}>Female</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} > <h4>Salary Imformation</h4></Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="current_salary"
                                    label="Current Salary"
                                    variant="outlined"
                                    placeholder="Enter Current Salary"
                                    fullWidth={true}
                                    required
                                    margin="normal"
                                    {...register("current_salary")}
                                    error={!!errors?.current_salary}
                                    helperText={errors.current_salary?.message}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="incentive_rate"
                                    label="Incentive Salary"
                                    variant="outlined"
                                    placeholder="Enter Incentive Salary"
                                    fullWidth={true}
                                    required
                                    margin="normal"
                                    {...register("incentive_rate")}
                                    error={!!errors?.incentive_rate}
                                    helperText={errors.incentive_rate?.message}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Salary Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-salary_type"
                                        id="salary_type"
                                        onChange={(e) => setSalarytype(e.target.value)}
                                        margin="normal"
                                        label="Salary Type"
                                    >
                                        <MenuItem hidden value="Select User Role">Select Salary Type</MenuItem>
                                        <MenuItem value={"monthly"}>Monthly</MenuItem>
                                        <MenuItem value={"weekly"}>Weekly</MenuItem>
                                        <MenuItem value={"hourly"}>Hourly</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                                    <Select
                                        labelId="demo-simple-salary_type"
                                        id="currency_code"
                                        onChange={(e) => setCurrency(e.target.value)}
                                        margin="normal"
                                        label="Currency"
                                    >
                                        <MenuItem hidden value="Select User Role">Select Currency</MenuItem>
                                        <MenuItem value={"DKK"}>DKK</MenuItem>
                                        <MenuItem value={"USD"}>USD</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"

                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >
        </>
    )
}
