import React, { useState, useEffect, use } from 'react'
import Navbar from '../../../components/Navbar'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useQuery } from '@tanstack/react-query';
import { Box, Typography } from '@mui/material';
import MaterialReactTable from "material-react-table";
import { useMemo } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Loaders from '../../../components/Loaders';
import { useSearchParams } from 'react-router-dom'

export default function Ambassadors() {
    const axiosPrivate = useAxiosPrivate();
    const [searchText, setSearchText] = useState("")

    const [searchParams, setSearchParams] = useSearchParams({ search: "" })
    const search = searchParams.get("search")

    useEffect(() => {
        if (search) {
            setSearchText(search)
            setSearchParams(prev => {
                prev.set("search", search)
                return prev
            })
        }
        else {
            setSearchText("")
            setSearchParams(prev => {
                prev.delete("search")
                return prev
            })
        }


    }, [search])

    useEffect(() => {
        console.log(searchText)
        if (searchText) {
            setSearchParams(prev => {
                prev.set("search", searchText)
                return prev
            })
        }
        else {
            setSearchParams(prev => {
                prev.delete("search")
                return prev
            })
        }
    }, [searchText])



    // useEffect(() => {
    //     console.log(search);
    // }, [search])


    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleAmbassadors = () => {
        const res = axiosPrivate.get(`/ambassador`)
        return res;
    };

    const { data: allAmbassadors = [], isLoading, refetch } = useQuery({
        queryKey: ['get-ambassadors'],
        queryFn: handleAmbassadors,
        cacheTime: 5000,
    })

    const handleDeteteAmbassadors = (id) => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const res = axiosPrivate.delete(`/ambassador/delete?ambassador_id=${id}`)
                if (res) {
                    swalWithBootstrapButtons.fire(
                        'Done!',
                        'Ambassador is Now Deleted :)',
                        'success'
                    )
                    refetch()
                }

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Customer is Now Default Mode :)',
                    'error'
                )
            }
        })

    };

    const handleAmbassadorCoupon = (id) => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const res = axiosPrivate.delete(`/coupon/delete?coupon_id=${id}`)
                if (res) {
                    swalWithBootstrapButtons.fire(
                        'Done!',
                        'Coupon is Now Deleted :)',
                        'success'
                    )
                }
            }
        })
    }

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => <>
                    {row?.image ? <img src={row?.image} alt="images" className='w-25' /> : <h1><i className="fa-regular fa-image w-50" style={{ color: "#3119e6", }} ></i></h1>}
                </>,
                id: "image", //id required if you use accessorFn instead of accessorKey
                header: "Image",
                Header: <i style={{ color: "oranage" }}>Image</i>,

            },

            {
                accessorKey: "name", //simple recommended way to define a column
                header: "Name",
                muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => `+45 ${row?.phone}`, //alternate way
                id: "phone", //id required if you use accessorFn instead of accessorKey
                header: "Phone Number",
                Header: <i style={{ color: "red" }}>Phone Number</i> //optional custom markup
            },
            {
                accessorFn: (row) => row?.role, //alternate way
                id: "role", //id required if you use accessorFn instead of accessorKey
                header: "User Type",
                Header: <i style={{ color: "blue" }}>User Type</i> //optional custom markup
            },

            {
                accessorFn: (row) => row?.email, //alternate way
                id: "Code", //id required if you use accessorFn instead of accessorKey
                header: "Code",
                Header: <i style={{ color: "orange" }}>Email</i> //optional custom markup
            },

            {
                accessorFn: (row) => <div>
                    <button onClick={() => handleDeteteAmbassadors(row.id)} className='btn btn-danger mx-2 text-white fs-6'><i className="fa-solid fa-trash-can"></i></button>
                    <Link to={`/admin/dashboard/ambassadors/create-coupon/${row.id}`} className='btn btn-success me-2 text-white fs-6'><i className="fa-solid fa-plus"></i></Link>
                    <Link to={`/admin/dashboard/ambassadors/sales-report/${row.id}`} className='btn btn-info  text-white fs-6'><i class="fa-regular fa-file"></i></Link>
                </div>,
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "oranage" }}>Action</i> //optional custom markup


            }
        ],
        []
    );

    return (
        <>
            <Navbar
                title="Ambassadors"
                btnTitle="Add New Ambassador"
                btnLink="/admin/dashboard/ambassadors/create"

            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }
            <div className='container my-5'>
                {allAmbassadors?.data?.data && <MaterialReactTable

                    columns={columns}
                    data={allAmbassadors?.data?.data}
                    initialState={{ showColumnFilters: true, showGlobalFilter: true, globalFilter: search }}
                    muiSearchTextFieldProps={(data) => {
                        setSearchText(data.table.refs.searchInputRef?.current?.value ? data.table.refs.searchInputRef?.current?.value : "")
                    }}
                    renderDetailPanel={({ row }) => (

                        <Box
                        >
                            {row?.original.coupons.length > 0 ?
                                <>
                                    <Typography variant="h6" className='text-purple' gutterBottom component="div">
                                        All Coupons of {row?.original.name}
                                    </Typography>
                                    <table className="table">
                                        <thead>
                                            <tr>

                                                <th scope="col">Coupon Code</th>
                                                <th scope="col">Discount</th>
                                                <th scope="col">Kickback</th>
                                                <th scope="col"></th>

                                            </tr>
                                        </thead>
                                        {
                                            row?.original.coupons && row?.original.coupons.map((coupon) => {
                                                return (
                                                    <tbody>
                                                        <tr>
                                                            <td>{coupon?.code}</td>
                                                            <td>{coupon?.value} %</td>
                                                            <td>{coupon?.ambassador_percentage} %</td>

                                                            <td>
                                                                {
                                                                    row?.original.coupons.length > 1 && <button className='btn btn-danger text-white fs-6' onClick={() => handleAmbassadorCoupon(coupon?.id)}><i className="fa-solid fa-trash-can"></i></button>
                                                                }
                                                                <Link className='btn btn-info text-white fs-6 mx-2' to={`/admin/dashboard/ambassadors/update-coupon/${coupon?.id}`}><i className="fa-solid fa-pencil"></i></Link>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                )
                                            })
                                        }
                                    </table>
                                </>
                                : <h1 className='text-purple text-center'>No Coupons Found</h1>
                            }


                        </Box>
                    )}
                />}



            </div>
        </>
    )
}
