import React, { useState } from 'react'
import Navbar from '../../../components/Navbar'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useMutation } from '@tanstack/react-query';
import salary from '../../../assets/search.gif'
import Loaders from '../../../components/Loaders';

export default function SalarySatistics() {
    const [result, setResult] = useState({});
    const axiosPrivate = useAxiosPrivate();

    const postData = async (data) => {
        const response = axiosPrivate.get(`salary/statistics?start_date=${data.start_date}&end_date=${data.end_date}&is_payment_clear=${data.is_payment_clear}`)
        return response;
    }


    const { mutate, isLoading } = useMutation(postData, {
        onSuccess: (successData) => {
            setResult(successData.data.data);
        }
    })


    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        mutate(data);
    };
    return (
        <>
            <Navbar
                title="Salary Statistics"
            />


            <div className='container'>
                <div className="card m-5">
                    <div className="card-body">
                        <div className=' d-flex justify-content-between'>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='d-flex'>
                                    <div className='m-3'>
                                        <label>Start Date</label>
                                        <input type="date" className="form-control" {...register("start_date")} placeholder="Start Date" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>

                                    <div className='m-3'>
                                        <label>End Date</label>
                                        <input type="date" className="form-control" {...register("end_date")} placeholder="End Date" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>

                                    <div className='m-3'>
                                        <label>Payment Status</label>
                                        <select className="form-select" name='is_payment_clear' {...register("is_payment_clear")} >
                                            <option value={0}>Unpaid</option>
                                            <option value={1} >Paid</option>
                                        </select>
                                    </div>
                                    <button type="submit" className="btn btn-primary  searchButton">Search</button>
                                </div>
                            </form>


                            <div>
                                <img src={salary} className='salarySearch' alt='searchImage' />
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            {isLoading && <div className="d-flex justify-content-center align-items-center">
                <Loaders />
            </div>}

            {
                result?.total_cost ?
                    <div className='container d-flex justify-content-center align-items-center'>
                        <div className='card' style={{ width: "500px" }}>
                            <ol class="list-group table-bordered">
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                        <div class="fw-bold">B2B</div>

                                    </div>
                                    <span >{result?.b2b_cost} kr</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                        <div class="fw-bold">Prepacked</div>
                                    </div>
                                    <span >{result?.prepacked_cost} kr</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                        <div class="fw-bold">B2C</div>
                                    </div>
                                    <span >{result?.b2c_cost} kr</span>
                                </li>
                            </ol>

                        </div>
                        <div></div>
                    </div>

                    : <div className='container'>
                    </div>
            }

        </>
    )
}
