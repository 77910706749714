import React, { useMemo, useState } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useQuery } from '@tanstack/react-query';
import Navbar from '../../../components/Navbar';
import Loaders from '../../../components/Loaders';
import MaterialReactTable from 'material-react-table';

export default function CompanySolution() {
    const [archived, setArchived] = useState(false)
    const axiosPrivate = useAxiosPrivate();

    const handleCompanySolution = () => {
        const res = axiosPrivate.get(`/company-solution/all`)
        return res;
    };

    const handleArchiveSubmit = (id) => {
        const res = axiosPrivate.put(`/company-solution/archived/${id}`)
        res.then((res) => {
            if (res?.data?.success) {
                refetch()
            }
        })
        return res;
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "name", //simple recommended way to define a column
                header: "Name",
                muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => `+45 ${row?.phone}`, //alternate way
                id: "phone", //id required if you use accessorFn instead of accessorKey
                header: "Phone Number",
                Header: <i style={{ color: "red" }}>Phone Number</i> //optional custom markup
            },
            {
                accessorFn: (row) => `${row?.cvr_number ? row?.cvr_number : 'N/A'}`, //alternate way
                id: "cvr_number", //id required if you use accessorFn instead of accessorKey
                header: "CVR Number",
                Header: <i style={{ color: "orange" }}>CVR Number</i> //optional custom markup
            },
            {
                accessorFn: (row) => row?.email, //alternate way
                id: "email", //id required if you use accessorFn instead of accessorKey
                header: "Email",
                Header: <i style={{ color: "blue" }}>Email</i> //optional custom markup
            },
            {
                accessorFn: (row) => <div>
                    {
                        row.archived ?
                            <button onClick={() => handleArchiveSubmit(row.id)} className='btn btn-danger mx-2 text-white fs-6' data-toggle="tooltip" data-placement="bottom" title="Unarchived"><i class="fa-solid fa-box-open"></i></button>
                            :
                            <button onClick={() => handleArchiveSubmit(row.id)} className='btn btn-success mx-2 text-white fs-6' data-toggle="tooltip" data-placement="bottom" title="Archived"><i class="fa-solid fa-box"></i></button>
                    }

                </div>,
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "oranage" }}>Action</i> //optional custom markup


            }
        ],
        []
    );

    const { data: allCompanySolution = [], isLoading, refetch } = useQuery({
        queryKey: ['get-company-solution'],
        queryFn: handleCompanySolution,
        cacheTime: 5000,
    })

    const handleArchive = (value) => {
        setArchived(value)
    }

    return (
        <>
            <Navbar
                title="Company Solution"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }
            <div className='container my-5'>

                {allCompanySolution?.data && <>
                    {

                        archived ? <button className='btn btn-primary  float-end' onClick={() => handleArchive(false)}> Show Unarchived List</button>
                            : <button className='btn btn-info text-white  float-end' onClick={() => handleArchive(true)}> Show Archived List</button>
                    }
                    <MaterialReactTable
                        columns={columns}
                        data={allCompanySolution?.data?.data.filter(item => {
                            if (!archived) {
                                return !item?.archived
                            } else {
                                return item?.archived
                            }
                        })} />
                </>}
            </div>
        </>
    )
}
