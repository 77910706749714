import { memo } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes.js'
import { Switch } from '@mui/material'
import { Link } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate.js'
import Swal from 'sweetalert2'


const style = {
    boxShadow: '10px 10px 10px #44444414',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
}
const label = { inputProps: { 'aria-label': 'Switch demo' } };
export const Card = memo(function Card({ cards, id, text, moveCard, findCard, tagName, status, isNew, isTop }) {
    const axiosPrivate = useAxiosPrivate();
    const originalIndex = findCard(id).index
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { id: droppedId, originalIndex } = item
                const didDrop = monitor.didDrop()
                if (!didDrop) {
                    moveCard(droppedId, originalIndex)
                }
                //get the new position of the card all cards
                const newCards = cards?.map((card, index) => {
                    return { ...card, position: index + 1 }
                }
                )
                const newCardsPosition = newCards?.map((card) => {
                    return card.id
                })

                //send the new position of the card to the backend
                axiosPrivate.put(`/menu-item/sort-alignment`, { sorted_array: newCardsPosition, is_top: isTop }).then((res) => {

                }
                ).catch((err) => {

                }
                )
            },
        }),
        [id, originalIndex, moveCard],
    )

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleOnClick = (id) => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const res = axiosPrivate.delete(`/menu-item/delete?menu_id=${id}&is_top=${isTop === true ? 1 : false} `)
                if (res) {
                    swalWithBootstrapButtons.fire(
                        'Done!',
                        'Menu is Now Deleted :)',
                        'success'
                    )
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000);
                }
            }
        })
    }

    const handleOnChange = (value, id) => {
        axiosPrivate.put(`/menu-item/update-status?menu_id=${id} `, { is_active: value }).then((res) => {

        }
        ).catch((err) => {

        }
        )
    }

    const handleOnNewChange = (value, id) => {
        axiosPrivate.put(`/menu-item/update-isNew?menu_id=${id}`, { is_new: value }).then((res) => {

        }
        ).catch((err) => {

        }
        )
    }

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ id: draggedId }) {
                if (draggedId !== id) {
                    const { index: overIndex } = findCard(id)
                    moveCard(draggedId, overIndex)
                }
            },
        }),
        [findCard, moveCard],
    )
    const opacity = isDragging ? 0 : 1

    return (
        <tr ref={(node) => drag(drop(node))} style={{ ...style, opacity }}>
            <td className='text-start '>
                <i className="fa-solid fa-bars me-5"></i>  {text}
            </td>
            <td className='text-start '>
                {tagName}
            </td>
            {
                !isTop && <td className='text-start'>
                    <Switch {...label} defaultChecked={isNew} onChange={(e) => handleOnNewChange(e.target.checked, id)} />

                </td>
            }
            <td className='text-start'>
                <Switch {...label} defaultChecked={status} onChange={(e) => handleOnChange(e.target.checked, id)} />
            </td>

            <td className='text-start'>
                <Link className='btn btn-sm btn-success mx-2 w-25' to={`/admin/dashboard/category-menu/edit/${id} `} ><i className="fa-regular fa-pen-to-square"></i></Link>
                <button className='btn btn-sm btn-danger' onClick={() => handleOnClick(id)}><i className="fa-solid fa-trash-can"></i></button>
            </td>
        </tr>

    )
})
