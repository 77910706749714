import { Box, IconButton, Tooltip } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo, useState } from 'react';
import Navbar from '../../../components/Navbar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import MaterialReactTable from "material-react-table";
import { useForm } from 'react-hook-form';
import Loaders from '../../../components/Loaders';

export default function Permission() {
    const [validationErrors, setValidationErrors] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const postData = async (data) => {
        const response = axiosPrivate.post('permission/create', {
            name: data.name
        });
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            refetch()

        }
    })


    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        mutate(data);
    };
    const handlePermission = () => {
        const res = axiosPrivate.get(`permission/all`)
        return res;
    };
    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {

        const res = await axiosPrivate.put(`permission/update/${row.original.id}`, {
            name: values.name
        });

        if (res.status === 200) {
            exitEditingMode();
            refetch()
        }


    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Permission Name",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }), //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            }
        ],
        [getCommonEditTextFieldProps],
    );

    const { data: allPermission = [], isLoading, refetch } = useQuery({
        queryKey: ['get-permission'],
        queryFn: handlePermission,
        cacheTime: 5000,
    })
    return (
        <>
            <Navbar
                title="Manage Permission"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }



            <div className='container my-5'>


                {allPermission?.data?.data &&
                    <>
                        <button type="button" className="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Add new Permission</button>
                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Permission</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">Name:</label>
                                                <input type="text" className="form-control" id="recipient-name" {...register("name")} />
                                            </div>
                                            <input type="submit" className="btn btn-login" data-bs-dismiss="modal" value="submit" />

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <MaterialReactTable
                            columns={columns}
                            data={allPermission?.data?.data}
                            enableEditing
                            onEditingRowSave={handleSaveRowEdits}
                            onEditingRowCancel={handleCancelRowEdits}
                            positionActionsColumn="last"
                            renderRowActions={({ row, table }) => (
                                <Box sx={{ display: 'flex-reverse', gap: '1rem' }}>
                                    <Tooltip arrow placement="left" title="Edit">
                                        <IconButton className='btn btn-success btn-square mx-2' onClick={() => table.setEditingRow(row)}>
                                            <i className="fa-solid fa-pencil " ></i>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}

                        />
                    </>




                }

            </div>
        </>
    )
}
