import MaterialReactTable from 'material-react-table'
import React, { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query';
import Navbar from '../../../components/Navbar';
import Loaders from '../../../components/Loaders';
import axios from '../../../api/axios';

export default function CustomerFeedbacks() {

    const handleFeedbacks = () => {
        const res = axios.get(`order/dashboard/feedback`)
        return res;
    };

    const handleDatetime = (value) => {
        const timestamp = value;
        const dateObj = new Date(timestamp);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(dateObj.getDate()).padStart(2, '0');

        const extractedDate = `${year}-${month}-${day}`;

        return extractedDate;
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: "comment", //simple recommended way to define a column
                header: "Feedback text",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorFn: (row) => row?.packing_order?.order_tracking_id, //simple recommended way to define a column
                header: "OrderID",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => row?.user?.name, //simple recommended way to define a column
                header: "Customer name",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },


            {
                accessorFn: (row) => handleDatetime(row?.created_at), //simple recommended way to define a column
                header: "Order date",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorFn: (row) => row?.packing_order?.employee_name, //simple recommended way to define a column
                header: "Packer Name",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            // {
            //     accessorFn: (row) =>
            //         <>


            //         </>
            //     , //alternate way
            //     id: "id", //id required if you use accessorFn instead of accessorKey
            //     header: "Action",
            //     Header: <i style={{ color: "oranage" }}>Action</i> //optional custom markup

            // }
        ],
        [],
    );


    const { data: allFeedbacks = [], isLoading, refetch } = useQuery({
        queryKey: ['get-feedbacks'],
        queryFn: handleFeedbacks,
        cacheTime: 5000,
    })
    return (
        <div>   <Navbar
            title="Customer Feedbacks"
        />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }

            <div className='container my-5'>


                {allFeedbacks?.data?.data &&
                    <>

                        <MaterialReactTable
                            columns={columns}
                            data={allFeedbacks?.data?.data.filter(item => item.comment)}
                        />
                    </>
                }

            </div>
        </div>
    )
}
