
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import Navbar from '../../../components/Navbar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import MaterialReactTable from "material-react-table";
import Loaders from '../../../components/Loaders';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function SalesLadder() {

    const axiosPrivate = useAxiosPrivate();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleProductDelete = (id) => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const res = axiosPrivate.delete(`sale-ladder/delete/${id}`);
                res.then(() => {
                    swalWithBootstrapButtons.fire(
                        'Deleted!',
                        'Your Candy has been deleted.',
                        'success'
                    )
                    refetch();
                })

            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })

    }

    const handleSalesLadder = () => {
        const res = axiosPrivate.get(`sale-ladder/all`)
        return res;
    };


    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => row.from,
                header: "from",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.to,
                header: "to",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.banner_en_text,
                header: "Banner text",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.en_text,
                header: "Achievement Text",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => (
                    <>
                        <Link to={`/admin/dashboard/sales-ladder/edit/${row.id}`} className='btn btn-success '><i className="fa-regular fa-pen-to-square"></i></Link>
                        <button onClick={() => handleProductDelete(row.id)} className='btn btn-danger mx-2'><i className="fa-solid fa-trash" ></i></button>
                    </>
                ),
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "red" }}>Action</i> //
            }
        ],
        [],
    );

    const { data: allSalesLadder = [], isLoading, refetch } = useQuery({
        queryKey: ['get-salesLadder'],
        queryFn: handleSalesLadder,
        cacheTime: 5000,
    })
    return (
        <>
            <Navbar
                title="Sales Ladder"
                btnTitle="Add New tier"
                btnLink="/admin/dashboard/sales-ladder/create"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }



            <div className='container my-5'>


                {allSalesLadder?.data?.data &&
                    <>
                        <MaterialReactTable
                            columns={columns}
                            data={allSalesLadder?.data?.data}
                        />
                    </>
                }
            </div>
        </>
    )
}
