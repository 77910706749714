import React from 'react'
import Navbar from '../../../../components/Navbar'
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Loaders from '../../../../components/Loaders';


export default function UpdateCategoryMenu() {
    const axiosPrivate = useAxiosPrivate();
    const [istagNameUnique, setistagNameUnique] = useState(true);
    const params = useParams();
    const [menuDetails, setMenuDetails] = useState({});
    const checkTagNameUnique = async (value) => {
        try {
            const response = await axiosPrivate.post("menu-item/check-uniqueness", { "tag_name": value });

            if (response.data.success === false) {

                return false;
            }
            return true;
        } catch (error) {

            console.error(error);
        }
    };

    const handleTagNameUniqueBlur = async (event) => {
        const tag = event.target.value;
        if (tag === menuDetails.tag_name) {
            setistagNameUnique(true);
            return;
        }
        const isUnique = await checkTagNameUnique(tag);
        setistagNameUnique(isUnique);
    };

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();

    const postData = async (data) => {
        const response = axiosPrivate.put(`/menu-item/update?menu_id=${params.id}`, {
            display_name: data.display_name,
            group: data.group,
            is_in_top_list: data.is_in_top_list,
            is_in_vertical_list: data.is_in_vertical_list,
            tag_name: data.tag_name,
            top_list_position: menuDetails?.top_list_position ? menuDetails?.top_list_position : 0,
            vertical_list_position: menuDetails?.vertical_list_position ? menuDetails?.vertical_list_position : 0,
            is_active: true
        });
        return response;
    }

    const handleMenuDetails = () => {
        const res = axiosPrivate.get(`/menu-item/details/${params.id}`)
        return res;
    };


    const { isLoading } = useQuery({
        queryKey: 'menuDetails',
        queryFn: handleMenuDetails,
        onSuccess: (data) => {
            setMenuDetails(data?.data?.data)
        }
    })

    useEffect(() => {
        Object.keys(menuDetails).forEach((key) => {
            setValue(key, menuDetails[key]);
        });
    }, [menuDetails, setValue])

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/category-menu'
                }, 3000)
            }
        }
    })

    const onSubmit = (data) => {
        mutate(data);
    }
    return (
        <>
            <Navbar
                title={'Update Category Menu'}
            />
            {
                isLoading && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Loaders />
                    </div>
                )
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="display_name"
                                    label="Display Name"
                                    variant="outlined"
                                    placeholder="Enter Display Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    required={true}
                                    margin="normal"
                                    {...register("display_name")}
                                    error={!!errors?.display_name}
                                    helperText={errors.display_name?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="tag_name"
                                    label="Tag Name"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required={true}
                                    placeholder="Enter Tag Name"
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("tag_name")}
                                    onBlur={handleTagNameUniqueBlur} // <-- Add onBlur event handler
                                    error={!istagNameUnique} // <-- Set error state based on istagNameUnique
                                    helperText={!istagNameUnique ? "This tag_name is already taken" : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel id="demo-simple-select-label">In Top Category Menu</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_in_top_list"
                                        required={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("is_in_top_list")}
                                        label="In Top Category Menu"
                                        value={watch("is_in_top_list") || 0}
                                        onChange={(event) => {
                                            setValue("is_in_top_list", event.target.value)
                                        }}
                                    >
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel id="demo-simple-select-label">In Vertical Category Menu</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_in_vertical_list"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        required={true}
                                        {...register("is_in_vertical_list")}
                                        label="In Top Category Menu"
                                        value={watch("is_in_vertical_list") || 0}
                                        onChange={(event) => {
                                            setValue("is_in_vertical_list", event.target.value)
                                        }}
                                    >
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel id="demo-simple-select-label">Category Group</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="group"
                                        {...register("group")}
                                        required={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Category Group"
                                        value={watch("group") || ""}
                                        onChange={(event) => {
                                            setValue("group", event.target.value)
                                        }}
                                    >
                                        <MenuItem value="inhold">InHold</MenuItem>
                                        <MenuItem value="kategori">Kategori</MenuItem>
                                        <MenuItem value="favoritter">Favoritter</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"
                                    disabled={!istagNameUnique} // <-- Disable button if code is not unique
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >

        </>
    )
}
