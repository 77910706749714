import React, { useMemo } from 'react'
import Navbar from '../../../components/Navbar'
import Loaders from '../../../components/Loaders'
import MaterialReactTable from 'material-react-table'
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Switch } from '@mui/material';

export default function Coupon() {
    const axiosPrivate = useAxiosPrivate();
    const postData = async (data) => {
        const response = axiosPrivate.post('product-adjustment/create', {
            name: data.name
        });
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            refetch()

        }
    })

    const handleCouponStatus = (id, value) => {
        const res = axiosPrivate.put(`coupon/status?coupon_id=${id}`)
        refetch()
        return res

    }

    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        mutate(data);
    };
    const handleCoupon = () => {
        const res = axiosPrivate.get(`coupon/all`)
        return res;
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "code", //simple recommended way to define a column
                header: "Code",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorKey: "coupon_type", //simple recommended way to define a column
                header: "Coupon Type",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorKey: "value_type", //simple recommended way to define a column
                header: "Value Type",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },


            {
                accessorKey: "value", //simple recommended way to define a column
                header: "Value",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => <Switch defaultChecked={(row?.is_freezed !== 'freez' && row?.is_used == 0) ? true : false} onChange={(e) => handleCouponStatus(row.id, e.target.checked)} />, //alternate way
                id: "is_freezed", //id required if you use accessorFn instead of accessorKey
                header: "Status",
                Header: <i style={{ color: "#6e4695" }}>Status</i> //optional custom markup
            },

            // {
            //     accessorFn: (row) =>
            //         <>


            //         </>
            //     , //alternate way
            //     id: "id", //id required if you use accessorFn instead of accessorKey
            //     header: "Action",
            //     Header: <i style={{ color: "oranage" }}>Action</i> //optional custom markup

            // }
        ],
        [],
    );


    const { data: allCandy = [], isLoading, refetch } = useQuery({
        queryKey: ['get-coupon'],
        queryFn: handleCoupon,
        cacheTime: 5000,
    })
    return (
        <div>   <Navbar
            title="Coupons"
            btnTitle="Add new Coupon Code"
            btnLink="/admin/dashboard/coupons/add"
        />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }

            <div className='container my-5'>


                {allCandy?.data?.data &&
                    <>

                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Create Coupon</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">Code:</label>
                                                <input type="text" className="form-control" id="recipient-name" {...register("name")} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">Value:</label>
                                                <input type="text" className="form-control" id="recipient-name" {...register("value")} />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">Coupon Type:</label>
                                                <select class="form-select" aria-label="Default select example" {...register("coupon_type")}>
                                                    <option value="individual">Individual</option>
                                                    <option value="campaign">Campaign</option>
                                                    <option value="phone">Phone</option>
                                                </select>
                                            </div>
                                            <input type="submit" className="btn btn-login" data-bs-dismiss="modal" value="submit" />

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <MaterialReactTable
                            columns={columns}
                            data={allCandy?.data?.data}
                        />
                    </>




                }

            </div>
        </div>
    )
}
