import React, { useState } from 'react'
import Navbar from '../../../../components/Navbar'
import { Grid, TextField } from '@mui/material'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation } from '@tanstack/react-query';

export default function CreateAmbassadors() {
    const axiosPrivate = useAxiosPrivate();
    const [isCodeUnique, setIsCodeUnique] = useState(true);
    const [code, setCode] = useState()
    const checkCodeUnique = async (value) => {
        try {
            const response = await axiosPrivate.post("coupon/check-unique", { "code": value });

            if (response.data.success === false) {

                return false;
            }
            return true;
        } catch (error) {

            console.error(error);
        }
    };

    const handleCodeBlur = async (event) => {
        const code = event.target.value;
        setCode(code)
        const isUnique = await checkCodeUnique(code);
        setIsCodeUnique(isUnique);
    };

    const { register, handleSubmit, formState: { errors } } = useForm();

    const postData = async (data) => {
        const response = axiosPrivate.post('coupon/with-ambassador', {
            name: data.name,
            phone: data.phone,
            email: data.email,
            code: data.code,
            value: data.value,
            coupon_type: 'campaign',
            ambassador_percentage: data.ambassador_percentage
        });
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/ambassadors'
                }, 3000)
            }
        }
    })

    const onSubmit = (data) => {
        mutate(data);
    }
    return (
        <>
            <Navbar
                title="Create a new Ambassador"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    placeholder="Enter Ambassador Name"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("name")}
                                    error={!!errors?.name}
                                    helperText={errors.name?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="phone"
                                    label="Phone"
                                    variant="outlined"
                                    placeholder="Enter Phone Number"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("phone")}
                                    error={Boolean(errors?.phone)}
                                    helperText={errors.phone ? errors.phone?.message : ""}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    placeholder="Enter Email"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("email")}
                                    error={Boolean(errors?.email)}
                                    helperText={errors.short_code ? errors.email?.message : ""}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="code"
                                    label="Code"
                                    variant="outlined"
                                    placeholder="Enter Code"
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("code")}
                                    onBlur={handleCodeBlur} // <-- Add onBlur event handler
                                    error={isCodeUnique === false} // <-- Set error state based on isCodeUnique
                                    helperText={code && (isCodeUnique === false ? <p>Code is NOT available</p> : <p className='text-success'>Code is available </p>)}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="value"
                                    label="Discount %"
                                    variant="outlined"
                                    placeholder="Enter Discount %"
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("value")}
                                    error={Boolean(errors?.value)}
                                    helperText={errors.value ? errors.value?.message : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="ambassador_percentage"
                                    label="Kickback %"
                                    variant="outlined"
                                    placeholder="Enter Kickback %"
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("ambassador_percentage")}
                                    error={Boolean(errors?.ambassador_percentage)}
                                    helperText={errors.ambassador_percentage ? errors.ambassador_percentage?.message : ""}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"
                                    disabled={!isCodeUnique} // <-- Disable button if code is not unique
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >
        </>
    )
}
