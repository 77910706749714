import React, { useState } from 'react'
import Navbar from '../../../../components/Navbar'
import { Grid, TextField, InputLabel, MenuItem, Select, FormControl } from '@mui/material'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from '@tanstack/react-query';

export default function SalesLadderCreate() {
    const axiosPrivate = useAxiosPrivate();

    const handleProduct = () => {
        const res = axiosPrivate.get('sale-ladder/product')
        return res;
    }

    const { data: allProduct = [], isLoading, refetch } = useQuery({
        queryKey: ['get-product'],
        queryFn: handleProduct,
        cacheTime: 5000,
    })



    const { register, handleSubmit, setValue, watch, getValues, formState: { errors } } = useForm();

    const postData = async (data) => {
        const makeData = {
            from: data.from,
            to: data.to,
            en_text: data.en_text,
            da_text: data.da_text,
            banner_en_text: data.banner_en_text,
            banner_da_text: data.banner_da_text,
            next_en_text: data.next_en_text,
            next_da_text: data.next_da_text,
            type: data.type,
            achievement: data.achievement,
            product_id: data.type === "product" ? data.product_id : null
        }

        const response = axiosPrivate.post('sale-ladder/create-new', makeData);
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/sales-ladder'
                }, 3000)
            }
        }
    })

    const onSubmit = (data) => {
        mutate(data);
    }
    return (
        <>
            <Navbar
                title="Create a new Tier"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="from"
                                    label="From (Price)"
                                    variant="outlined"
                                    placeholder="Enter From (Price)"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("from")}
                                    error={!!errors?.from}
                                    helperText={errors.from?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="to"
                                    label="To (Price)"
                                    variant="outlined"
                                    placeholder="Enter To (Price)"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("to")}
                                    error={Boolean(errors?.to)}
                                    helperText={errors.to ? errors.to?.message : ""}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="en_text"
                                    label="Banner Text (English)"
                                    variant="outlined"
                                    placeholder="Enter Banner Text (English)"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("banner_en_text")}
                                    error={Boolean(errors?.banner_en_text)}
                                    helperText={errors.banner_en_text ? errors.banner_en_text?.message : ""}
                                />
                               
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="da_text"
                                    label="Banner Text (Danish)"
                                    variant="outlined"
                                    placeholder="Enter Banner Text(Danish)"
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("banner_da_text")}
                                    error={Boolean(errors?.banner_da_text)}
                                    helperText={errors.banner_da_text ? errors.banner_da_text?.message : ""}
                                />
                                
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="en_text"
                                    label=" Achievement Text(English)"
                                    variant="outlined"
                                    placeholder="Enter Achievement Text (English)"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("en_text")}
                                    error={Boolean(errors?.en_text)}
                                    helperText={errors.en_text ? errors.en_text?.message : ""}
                                />
                               
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="da_text"
                                    label=" Achievement Text (Danish)"
                                    variant="outlined"
                                    placeholder="Enter Achievement Text(Danish)"
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("da_text")}
                                    error={Boolean(errors?.da_text)}
                                    helperText={errors.da_text ? errors.da_text?.message : ""}
                                />
                                
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="en_text"
                                    label="Next Text (English)"
                                    variant="outlined"
                                    placeholder="Enter Next Text (English)"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("next_en_text")}
                                    error={Boolean(errors?.next_en_text)}
                                    helperText={errors.next_en_text ? errors.next_en_text?.message : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="da_text"
                                    label="Next Text (Danish)"
                                    variant="outlined"
                                    placeholder="Enter Next Text(Danish)"
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("next_da_text")}
                                    error={Boolean(errors?.next_da_text)}
                                    helperText={errors.next_da_text ? errors.next_da_text?.message : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>

                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Tier Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="type"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("type")}
                                        value={watch("type") || ""}
                                        label="Tier type"
                                        onChange={(event) => {
                                            setValue("type", event.target.value)
                                        }}
                                    >
                                        <MenuItem value="shipping">Shipping</MenuItem>
                                        <MenuItem value="product">Product</MenuItem>
                                        <MenuItem value="minimum_order">Minimum  Order</MenuItem>

                                    </Select> </FormControl>


                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="achievement"
                                    label="Achievement"
                                    variant="outlined"
                                    placeholder="Enter achievement"
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("achievement")}
                                    error={Boolean(errors?.achievement)}
                                    helperText={errors.achievement ? errors.achievement?.message : ""}
                                />
                            </Grid>
                            {((getValues('type') === 'product')) &&
                                <Grid item xs={6}>
                                    <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                        <InputLabel id="demo-simple-select-label">Product</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="type"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            {...register("product_id")}
                                            value={watch("product_id") || ""}
                                            label="Product id"
                                            onChange={(event) => {
                                                setValue("product_id", event.target.value)
                                            }}
                                        >
                                            {
                                                allProduct?.data?.data.map((product) => (
                                                    <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>
                                                ))
                                            }
                                        </Select> </FormControl>

                                </Grid>
                            }

                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >
        </>
    )
}
