import React, { useMemo, useState } from 'react'
import Navbar from '../../../../components/Navbar'
import Loaders from '../../../../components/Loaders'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import MaterialReactTable from 'material-react-table';
import { Box } from '@mui/material';
import useAuth from '../../../../hooks/useAuth';

export default function SalesReport() {
    const axiosPrivate = useAxiosPrivate();
    const [adjust, setAdjust] = useState()
    const [value, setValue] = useState()
    const params = useParams();
    const { userInfo } = useAuth();

    const handleAmbassadors = () => {
        const res = axiosPrivate.get(`ambassador/sales-report?ambassador_id=${params.id}`)
        return res;
    };

    const handleCredit = (e) => {
        e.preventDefault();

        if (adjust == 2) {
            if (value > salesReports?.data?.data.credit) {
                alert("You can not subtract more than your credit")
                return
            }
            setAdjust('')
            setValue('')
        }
        const data = {
            ambassador_id: params.id,
            purpose: adjust,
            credit: value,
            description: `Adjust credit by ${userInfo?.name}`
        }

        axiosPrivate.put(`ambassador/adjust-credit`, data).then((res) => {
            refetch();
        })


    }

    const { data: salesReports = [], isLoading, refetch } = useQuery({
        queryKey: ['get-sales'],
        queryFn: handleAmbassadors,
        cacheTime: 5000,
    })

    const columns = useMemo(
        () => [

            {
                accessorKey: "code", //simple recommended way to define a column
                header: "Code",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } }, //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorFn: (row) => <>
                    {row.is_freezed === 'freez' ? <span class="badge bg-danger">Freez</span> : <span class="badge bg-success">Active</span>}
                </>, //simple recommended way to define a column
                header: "Status",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } }, //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorFn: (row) => <>
                    {row.value} %
                </>, //simple recommended way to define a column
                header: "Discount",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } }, //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorFn: (row) => <>
                    {row.ambassador_percentage} %
                </>, //simple recommended way to define a column
                header: "Ambassador Percentage",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } }, //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            //    
        ],
        []
    );

    return (
        <>
            <Navbar title="Sales Report" />

            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            ) :
                <div>
                    <div>
                        <div className='container my-3'>
                            <div className='card'>
                                <div className='d-flex justify-content-between '>
                                    <h5 >Remaining Credit : {salesReports?.data?.data.credit}</h5>
                                    <button className='btn btn-info' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Adjust credit
                                    </button>

                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Adjust Credit</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <form onSubmit={handleCredit}>
                                                        <div class="mb-3">
                                                            <label for="formFile" class="form-label">Adjust Type</label>
                                                            <select class="form-select" aria-label="Default select example" onChange={e => setAdjust(e.target.value)}>
                                                                <option selected hidden>Select Type</option>
                                                                <option value="1">Add</option>
                                                                {salesReports?.data?.data.credit > 0 && <option value="2">Subtract</option>}
                                                            </select>
                                                        </div>
                                                        <div class="mb-3">
                                                            <label for="formFile" class="form-label">Value</label>
                                                            <input class="form-control" type="number" onChange={e => setValue(e.target.value)} />
                                                        </div>
                                                        <button type="sumbit" class="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4>Ambassador Sales report by order</h4>
                    <div className='container'>
                        {salesReports?.data?.data?.sales_report && <MaterialReactTable
                            columns={columns}
                            data={salesReports?.data?.data.sales_report}

                            renderDetailPanel={({ row }) => (

                                <Box
                                >
                                    {row?.original.order.length > 0 ?
                                        <>

                                            <table className="table">
                                                <thead>
                                                    <tr>

                                                        <th scope="col">Order Tracking Id</th>
                                                        <th scope="col">Order Channel</th>
                                                        <th scope="col">Amount customer paid</th>
                                                        <th scope="col">Ambassador credits earned</th>

                                                    </tr>
                                                </thead>
                                                {
                                                    row?.original.order && row?.original.order.map((or) => {
                                                        return (
                                                            <tbody>
                                                                <tr>
                                                                    <td>{or?.order_tracking_id}</td>
                                                                    <td>{or?.order_channel.toLowerCase() === "app" ? (
                                                                        <span class="badge bg-success">{or?.order_channel}</span>
                                                                    ) : (
                                                                        <span class="badge bg-info">{or?.order_channel}</span>
                                                                    )}</td>
                                                                    <td>{or?.total_bill} </td>
                                                                    <td>{(or?.total_bill * (row?.original.ambassador_percentage / 100)).toFixed(2)}</td>


                                                                </tr>
                                                            </tbody>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </>
                                        : <h1 className='text-purple text-center'>No Order Sales Found</h1>
                                    }


                                </Box>
                            )}
                        />
                        }
                    </div>

                </div>
            }




        </>

    )
}
